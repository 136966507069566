import React from "react"
import { Row, Container, Col, DropdownButton, Dropdown, Breadcrumb } from "react-bootstrap"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import MainCategoryCard from "../components/mainCategoryCard"

import { main_categories } from "../components/categoryInfo"

const uri = "/recipe-index/"

const RecipeIndexPage = ( { location, data : { images } } ) => {
  const searchQuery = location.state?.searchQuery

  var imgarr = []
  for (var img of images.edges) {
    imgarr[img.node.name] = img.node.childImageSharp.gatsbyImageData
  }

  const sideBarOptions = 
    <><div className="category-menu-top"><Link to="/recipe-index">Recipe Categories</Link></div>
      {main_categories.map( category => (
        <div key={category.link} className="d-flex category-menu-links ps-3">
          <Link to={category.link}>{category.name}</Link>
        </div>
        ))
      }
      <div><br/><br/><br/></div>
    </>

  const dropDownOptions = 
    <DropdownButton title="Recipe Categories">
      {main_categories.map( category => (
        <Dropdown.Item href={category.link}>{category.name}</Dropdown.Item>
          ))
      }
    </DropdownButton>
  
  return (
  <Layout pageInfo={{ pageName: "recipe-index", searchQuery: searchQuery }} sideBarOptions={sideBarOptions} dropDownOptions={dropDownOptions} sideBarWidth="185px">
    <Seo title="Browse Recipes" keywords={[`recipes`, `browse`]} description="Recipe index" url={uri} />

      <Container fluid className="text-center">
      <Row className="justify-content-left my-3">
        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Recipes</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row>
      <Col className="p-4">
        <h1>Main Recipe Categories</h1>
      </Col>
      </Row>
      <Row className="justify-content-center d-flex flex-wrap">
        {main_categories.map(rec => (
          <MainCategoryCard
            key={rec.link}
            title={rec.name}
            uri={rec.link}
            image={rec.image === "" ? null : imgarr[rec.image] }
            excerpt={rec.description}
            className="main-cat-card"
          />
        ))}
    </Row>
    </Container>
  </Layout>
)
}


export default RecipeIndexPage

export const recipeIndexQuery = graphql`
  query recipeIndexImages {
    images: allFile (filter: { sourceInstanceName: { eq: "recipe-index-images" }}) {
      edges {
        node {
          dir
          ext
          sourceInstanceName
          name
          childImageSharp {
            gatsbyImageData(width: 200)
          }
        }
      }
    }
  }
`
