import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from 'html-react-parser';
import { Card } from "react-bootstrap"

const MainCategoryCard = ({ title, uri, image, imageAlt, excerpt, className }) => {

  return (
    <>
      <Link to={uri}  className={className === null ? '' : className}>
        <Card className="m-2 p-1 main-cat-card">
            <Card.Body className="main-cat-body">
            <Card.Title>
                    {title}
            </Card.Title>
            <div>
              {parse(excerpt)}
              <br/>
              <div className="p-2 main-cat-image">

              {image === undefined || image === null ? <></> : 
                <GatsbyImage image={image} alt={imageAlt === undefined || imageAlt === null ? title : imageAlt} />}
                </div>
              
            </div>
            </Card.Body>
        </Card>
      </Link>
    </>
  )
}

export default MainCategoryCard
